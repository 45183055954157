.placeholder {
    margin: 15px;
    padding: 10px;
    border-radius: 5px;

    &.pulse div{
        animation: pulse 1s infinite ease-in-out;
        -webkit-animation: pulse 1s infinite ease-in-out;
    }

    &.wave div{
        animation: wave 1s infinite linear forwards;
        -webkit-animation: wave 1s infinite linear forwards;
        background: #f6f7f8;
        background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        background-size: 800px 104px;
    }

    div {
        background: #E8E8E8;
    }

    .square {
        float: left;
        width: 30px;
        height: 30px;
        margin: 0 0 10px;
    }

    .line {
        height: 12px;
        margin: 0 0 10px 50px;
        width: 70%;
    }

    .circle {
        float: left;
        width: 15px;
        height: 15px;
        margin: 0 15px 10px 0;
        border-radius: 15px;
    }
}


@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, .1);
    }
    50% {
        background-color: rgba(165, 165, 165, .3);
    }
    100% {
        background-color: rgba(165, 165, 165, .1);
    }
}

@-webkit-keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, .1);
    }
    50% {
        background-color: rgba(165, 165, 165, .3);
    }
    100% {
        background-color: rgba(165, 165, 165, .1);
    }
}

@keyframes wave {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

@-webkit-keyframes wave {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}