// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'NeutrifPro', Helvetica, Arial, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$white: #ffffff;
$blue: #3e6bec;
$indigo: #6574cd;
$purple: #6714de;
$pink: #f66d9b;
$red: #ff5555;
$orange: #f6993f;
$yellow: #fab81b;
$green: #00D38C;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$dusty-blue: #b9dbff;

$gray-100: #f2f2f2;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #404042;
$gray-800: #343a40;
$gray-900: #222328;

$gray-650: #bbbdbf;

$primary: $purple;
$secondary: $red;

$btn-padding-y: 5px;
$btn-padding-x: 20px;
$btn-border-radius: 7px;
$btn-border-radius-lg: 7px;
$btn-font-size: 14px;

$h1-font-size: 1.5rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1.125rem;

$border-radius: 5px;
