.shadow-drop-bottom {
    -webkit-animation: shadow-drop-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: shadow-drop-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.shadow-drop-center {
    -webkit-animation: shadow-drop-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: shadow-drop-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes shadow-drop-bottom {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.75);
        box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.75);
    }
}

@keyframes shadow-drop-bottom {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.75);
        box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.75);
    }
}

@-webkit-keyframes shadow-drop-center {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    }
}

@keyframes shadow-drop-center {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
    }
}