@font-face {
    font-family: "NeutrifPro";
    src: url('../webfonts/NeutrifPro-Regular.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-Regular.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-italic";
    src: url('../webfonts/NeutrifPro-RegularItalic.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-RegularItalic.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-bold";
    src: url('../webfonts/NeutrifPro-Bold.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-Bold.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-bold-italic";
    src: url('../webfonts/NeutrifPro-BoldItalic.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-light";
    src: url('../webfonts/NeutrifPro-Light.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-Light.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-light-italic";
    src: url('../webfonts/NeutrifPro-LightItalic.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-LightItalic.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-medium";
    src: url('../webfonts/NeutrifPro-Medium.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-Medium.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-medium-italic";
    src: url('../webfonts/NeutrifPro-MediumItalic.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-MediumItalic.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-semi-bold";
    src: url('../webfonts/NeutrifPro-SemiBold.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-SemiBold.woff') format('woff');
}

@font-face {
    font-family: "NeutrifPro-semi-bold-italic";
    src: url('../webfonts/NeutrifPro-SemiBoldItalic.woff2') format('woff2'),
         url('../webfonts/NeutrifPro-SemiBoldItalic.woff') format('woff');
}