@import 'variables';

.btn-square {
    height: 100px;
    width: 100px;
    padding: 15px 10px;
    border-radius: 10px;
    font-size: 11px;
}

.btn {
    &.btn-gray {
        background-color: $gray-500;
        color: $white;
        transition: opacity .3s ease-in-out;

        &:hover {
            opacity: .7;
        }
    }

    &.btn-white {
        background-color: $white !important;
    }
}
