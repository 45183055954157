@import 'variables';

#sidebar {
    &.closed {
        ul {
            text-align: center;
        }
    }
}


#burger {
    margin-top: 40px;
}

#mobile-logo {
    display: none;
}

.nav {
    overflow-y: auto;
}

.navbar-nav {
    .nav-item {
        .nav-link {
            color: #1E1F20;
            transition: all .3s ease-in-out;

            span {
                font-size: 16px;
                font-weight: 500;
                transition: all .3s ease-in-out;
            }

            .square {
                transition: all .3s ease-in-out;
            }

            &:hover {
                span {
                    opacity: .7;
                }

                .square {
                    transform: scale(1.1);
                }
            }

            &:active {

                .square {
                    background-color: #6515DD20;
                    transform: scale(1.1);
                }
            }
        }
    }
}

.square.d-inline-block.align-middle.active {
    background-color: #6515DD20;
}


@media screen and (min-width: 992px) {
    .navbar-toggler {
        display: none;
    }

    #burger {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    #sidebar {
        z-index: 99;

        .navbar-nav {
            display: block;
            position: absolute;
            left: -100%;
            width: 100%;
            background-color: $white;
            padding: 0 10px;
            top: 105px;
        }

        .mb-5, .my-4 {
            margin: 1rem auto !important;
        }
    }

    #right-sidebar {
        display: none;
        position: absolute;
        top: 100%;
        bottom: -100%;
        z-index: 110;
        width: 100%;
        height: 100%;
    }

    #mid-sidebar {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -100%;
        z-index: 110;
    }

    #burger {
        display: block;
    }
}

@media screen and (max-width: 480px) {
    #sidebar {
        .navbar-nav {
            top: 95px;
        }
    }
}

@media screen and (max-width: 360px) {
    #sidebar {
        .navbar-nav {
            top: 85px
        }
    }
}
