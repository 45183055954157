@import "variables";

html, body {
    color: $gray-700;
    font-style: normal;
    font-size: 18px;
}

h1, h2, h3 {
    font-family: "NeutrifPro-bold", Helvetica, Arial, sans-serif;;
}

h1 {
    font-size: 1.5rem;
    font-weight: 900;
}

h2 {
    font-size: 1.25rem;
}

h3 {
    font-size: 1.125rem;
}

h4, h5, h6, p {
    font-family: "NeutrifPro-Regular", Helvetica, Arial, sans-serif;
}

.text-light-gray {
    color: $gray-500;
}

.sub-text {
    color: $gray-650;
}

.small-font {
    font-size: 12px;
    font-family: "NeutrifPro-light", Helvetica, Arial, sans-serif;
}