$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import 'variables';
@import 'typography';
@import 'buttons';
@import 'shadows';
@import 'pulse';
@import 'nav';
@import 'slider';
@import 'tooltip';
@import 'csvupload';
@import 'fontface';
@import 'heartbeat';
@import '~bootstrap/scss/bootstrap';
@import "~vue-tour/dist/vue-tour.css";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~vue-multiselect/dist/vue-multiselect.min.css";


html,
body {
    height: 100%;
}

.bg-gray {
    background-color: $body-bg;
}

.auth-section {
    display: flex;
    margin: 0;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.navbar-dark {
    a {
        &.navbar-brand {
            font-size: 2.5em;
            line-height: 1em;
            font-weight: 800;
        }
    }
}

.m-b-md {
    margin-bottom: 30px;
}

.header-cta {
    background-color: $primary;
    color: $white;
}

.card-header {
    background-color: $primary;
    border: 0;
    color: $white;
}

.auth-section {
    color: $primary;

    .auth-wrapper {
        background-color: $primary;
    }
}

.home-container {
    background-color: $primary;
    color: $white;

    .links>a {
        color: $white;
        padding: 0 25px;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: .1rem;
        text-decoration: none;
        text-transform: uppercase;
    }

    .title {
        font-size: 4.5rem;
        color: $white;
    }
}

.cursor-pointer {
    cursor: pointer;
}


.video-player-wrapper {
    position: relative;

    .player-buttons {
        position: absolute;
        bottom: 20px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
    }

    .title-wrapper {
        position: absolute;
        bottom: 140px;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
    }

    .overlay-text {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        padding-top: 50px;
    }

    .cta-button {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.full-video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}


.pagination {
    overflow-x: auto;
}

.square {
    cursor: pointer;
    position: relative;
    background-color: #A3C5EB20;
    height: 40px;
    width: 40px;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;
    margin-right: 8px;
    transition: all 0.3s ease-in-out;
    border-color: $white;

    &:hover {
        transform: scale(1.1);
    }

    &:focus-visible {
        background: #6515DD20;
    }

    .fas,
    .far {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1rem;
        height: auto;
    }

    .video-icon {
        width: 2.4rem;
    }

    &.bg-success {
        background-color: #ccf6e8 !important;
        color: #00d28b;
    }

    &.bg-primary {
        background-color: #e0d0f7 !important;
        color: #6615dc;
    }

    &.bg-danger {
        background-color: #ffdddc !important;
        color: #ff5555;
    }
}

.boxes-container {
    .boxes {
        .box {
            border-radius: 12px;

            .number {
                font-size: 2.8rem;
            }

            p {
                font-size: .9rem;
            }
        }
    }
}

.search-contact {
    width: 85%;
}

.button-trigger {
    width: 50px;
    height: 50px;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid $gray-900;
}

.record-sub-text {
    margin-top: 10px;
    color: $gray-800;
    font-family: "NeutrifPro-light", Helvetica, Arial, sans-serif;
}

.main-content {
    overflow-y: auto;
    overflow-x: hidden;
}

.table-wrapper {
    .item {
        background-color: $gray-100;
        padding: 20px;
        color: $gray-900;

        transition: all .3s ease-in-out;

        &:hover {
            transform: scale(1.025);
        }

        .fa-user-circle {
            cursor: pointer;
            transition: opacity .3s ease-in-out;

            &:hover {
                opacity: .7;
            }
        }

        h4 {
            font-size: 1.1rem;
            font-weight: 800;
        }

        p {
            margin: 0;
        }

        .full-width {
            width: 100%;
        }
    }
}

.row [class*='col-'] {
    transition: flex 0.5s ease-in-out, max-width .5s ease-in-out;
}

#overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .7);
}

.list-style-none {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tour-overlay {
    .tour-modal {
        h2 {
            font-weight: bolder !important;
        }
    }
}

.highlight {
    border: 2px solid $warning;
}

@media screen and (min-width: 992px) {
    .csv-import-file {
        margin-left: 60px;
    }
}

@media screen and (max-width: 568px) {
    .home-container {
        .title {
            font-size: 2.5rem;
        }
    }

    #dashboard .mb-5,
    .row .my-5 {
        margin-bottom: 1rem !important;
    }

    .boxes-container {
        width: 100%;
        overflow-x: auto;

        .boxes {
            width: 905px;

            .row {
                display: inline-block;

                .col-md-4 {
                    width: 280px;
                    margin-right: 20px;
                    float: left;
                    height: 200px;

                    .box {
                        border-radius: 12px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}