@import "variables";

.upload-wrapper {
    border: 2px dashed $green;
    border-radius: 10px;
    padding: 5%;
    text-align: center;
}

.failedUpload {
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 300;
}

.import-table {
    font-size: 14px;
    color: #000 !important;
}

.table th, .table td {
    max-width: 100%;
    white-space: nowrap;
    padding: 5px 10px !important;
}

.table th {
    font-weight: 600 !important;
}

.fields > td {
    padding-top: 20px !important;
    font-weight: 300 !important;
}

.matchHeader {
    font-size: 13px !important;
}

.table th {
    border-bottom: 2px solid #000;
    border-top: none !important;
}

.table td {
    border: none !important;
}

.startUpload > h3 {
    color: #000;
}

.startUpload > p {
    font-weight: 300;
    line-height: 1.3em;
    margin-bottom: 50px;
    color: #000;
}

.uploadTitle {
    font-weight: 600;
}

.upload-instructions > li {
    margin-bottom: 12px;
}

.folder {
    margin-right: 10px;
}

.upload-wrapper > .fa-cloud-upload-alt {
    color: $green;
    font-size: 4rem;
}

.upload-wrapper > .form-control {
    padding: 0;
    border: none;
    font-size: 14px;
    width: auto;
    vertical-align: text-top;
    display: inline-table;
    margin-top: -20px;
}

.form-horizontal > .form-group {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
}

#csv_file {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

